.footer-container {
  color: #fff;
  background-color: #000000;
}

.footered {
  max-width: 95vw;
  margin: 0 auto;
}
.footered h2 {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 300;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 25px;
  gap: 30px;
}

.footer-section li {
  list-style-type: none;
}
.first-foot {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.first-foot h1 {
  font-size: 40px;
  line-height: 70px;
}
.first-foot h3 {
  font-size: 20px;
  line-height: 30px;
}
.first-foot h4 {
  font-size: 16px;

  line-height: 20px;
}

.first-foot ul li:first-child {
  font-size: 16px;
  padding-top: 20px;
  line-height: 30px;
}

.second-foot ul li a {
  text-decoration: none;
  color: #fff;
  font-family: "Lato", sans-serif;
  border-bottom: 1px solid #fff;
  font-size: 19px;
}

.second-foot ul li a:hover {
  border-bottom: 2px solid #fff;
}

.second-foot ul li {
  padding-top: 10px;
}

@media (max-width: 746px) {
  .footer-section {
    display: grid;
  }
  .second-foot {
    display: flex;
    justify-content: flex-end;
    text-align: end;
  }
}

.fa-fa-foot {
  padding-top: 20px;
}
.fa-fa-foot .fas,
.fa-fa-foot .fab {
  background-color: #000;
  color: #fff;
  font-size: 23px;
}

.fa-fa-foot i {
  padding-right: 23px;
}
