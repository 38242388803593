.slider {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
}

.slids {
  width: 100vw;
  height: 100vh;
  position: absolute;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-color: #313141;
  opacity: 1;
}

.slide a {
  text-decoration: none;
  color: #fff;
}

.slide a:hover {
  color: #0066ff;
}

.current .--btn {
  color: #fff;
  background-color: #0066ff;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.55rem;

  padding-bottom: 0.55rem;
  border-radius: 5px;
  border-width: none;
  cursor: pointer;
}

.current .--btn:hover {
  background-color: #fff;
  color: #0066ff;
}

.slide {
  position: absolute;
  z-index: -99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transform: translateX(-10%);
  transition: all 0.5s ease;
}
@media screen and (min-width: 900px) {
  .slids {
    width: 100vw;
    height: 100vh;
  }
}

.slids {
  height: 100vh;
}

.content {
  position: absolute;
  font-family: "Lato", sans-serif;
  top: 70vh;

  color: #fff;
  left: 5rem;
  opacity: 1;
  padding: 3rem;
  width: 90%;
  z-index: 999;
  padding: 3rem;
  background: rgba(0, 0, 0, 0.3);
  animation: slide-up 1s ease 0.5s;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 70vh;
  }

  100% {
    visibility: visible;
    top: 55vh;
  }
}

@media screen and (max-width: 900px) {
  .content {
    width: 90%;
    left: 0;
  }
}

.content > * {
  color: #fff;
  margin-bottom: 1rem;
}

.current {
  opacity: 1;
  transform: translateX(0%);
}

.current .content {
  opacity: 1;
}

.arrow {
  border: 2px solid #fff;
  border-radius: 50%;
  background: transparent;
  color: #fff;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  top: 50%;
}

.arrow:hover {
  background-color: #fff;
  color: #777;
}

.next {
  right: 1.5rem;
}

.prev {
  left: 1.5rem;
}

hr {
  height: 2px;
  background: #fff;
  width: 50%;
}

@media (max-height: 730px) {
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 70vh;
    }

    100% {
      visibility: visible;
      top: 45vh;
    }
  }
}
