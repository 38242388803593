.con-container {
  padding: 30px 0;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.con-coned {
  margin: 0 auto;
  box-shadow: 0 10px 30px rgba(56, 125, 255, 0.3);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.07));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.07));
  max-width: 55vw;
  padding: 30px 60px 60px 60px;
}

.con-coned h1 {
  padding-bottom: 30px;
}
.con-con {
  display: flex;
  justify-content: space-between;

  border-radius: 0.5rem;
  gap: 20px;
}

.con-first {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 20px;
}

.con-sec {
  display: flex;
  flex-direction: column;
}

.con-ins {
  padding: 20px 0;
}
.con-but {
}

.con-con button {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin-top: 30px;
  height: 28px;
  width: 80px;

  /* padding: 0.7rem 2.3rem; */
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #0066ff;
  color: #0066ff;
  cursor: pointer;
}

.con-con button:hover {
  background-color: #0066ff;
  color: #fff;
}

@media (max-width: 850px) {
  .con-con {
    flex-wrap: wrap;
  }
}

@media (max-width: 510px) {
  .con-coned {
    max-width: 80vw;
  }
}
