.all-live {
  background-image: url(../../assets/blues.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  color: #fff;
  font-family: "Lato", sans-serif;
  justify-content: center;
}

.live-con {
  background-color: rgba(75, 75, 75, 0.5);
  height: 100vh;
  text-align: center;
  padding: 100px 0 30px 0;
}
.live-con span {
  font-size: 50px;
}

.live-con h1:first-child {
  font-size: 60px;
  padding-bottom: 20px;
}
.live-con p:nth-child(1) {
  font-size: 40px;
}

.live-con h3 {
  font-size: 25px;
  padding-bottom: 20px;
}

.live-count {
  border: 2px solid #fff;
  border-radius: 0.5rem;
  flex-wrap: wrap;
  padding: 20px;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;

  gap: 20px;
}

.live-count h1 {
  font-size: 50px;
}

.live-count span {
  display: flex;

  /* gap: 20px; */
}

.live-button {
  background: none;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-color: #fff;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.live-button:hover {
  background-color: #284257;
}

.live-butt {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 20px;
  padding-top: 30px;
}

.live-links {
  display: flex;
  gap: 140px;

  justify-content: center;
}

.live-links .fab {
  padding-top: 10px;
  font-size: 30px;
}

@media (max-width: 900px) {
  .live-count {
    width: 75vw;
  }
}
@media (max-width: 768px) {
  .live-count {
    width: 85vw;
  }
}
@media (max-width: 520px) {
  .live-count {
    width: 95vw;
    gap: 10px;
  }
}
@media (max-width: 430px) {
  .live-con h1:first-child {
    font-size: 50px;
  }
}
@media (max-width: 458px) {
  .live-count {
    width: 100vw;
  }
  .live-count h1 {
    font-size: 30px;
  }
}
