* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.img-happen {
  background-image: url("../../assets/7super.jpg");

  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* max-width: 1600px; */
}

.happen-section {
  max-width: 85vw;
  margin: 50px auto;
}

.happen-article {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 20px;
  align-items: center;
}

.happen-wrapper {
  box-shadow: 0 10px 30px rgba(56, 125, 255, 0.3);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.07));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.07));
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-radius: 0.5rem;
  position: relative;
}

.happen-wrapper h1 {
  font-size: 23px;
}

@media (max-width: 1200px) {
  .happen-article {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 876px) {
  .happen-article {
    grid-template-columns: 1fr;
  }
}
