.join-container {
  font-family: "Lato", sans-serif;
  padding: 30px 0;
}

.join-section {
  border-radius: 1px solid #000;
}

.join-form {
  max-width: 55vw;
  margin: 0 auto;
  display: flex;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  box-shadow: 0 10px 30px rgba(56, 125, 255, 0.3);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.07));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.07));
  padding: 30px 0 70px 0;
}
.join-form h2 {
  text-align: center;
}

.ins {
  display: flex;
  justify-content: flex-start;

  align-items: baseline;
  gap: 10px;
  padding-top: 25px;
}

.texts input,
select {
  width: 200px;
  height: 25px;
  font-size: 14px;
  flex-wrap: wrap;
}

textarea {
  width: 170px;
  height: 200px;
}
.texts input {
  /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}
.join-form button {
  /* display: flex;
  justify-content: center;
  align-items: center; */

  padding: 0.7rem 2.3rem;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #0066ff;
  color: #0066ff;
  cursor: pointer;
}

.join-form button:hover {
  background-color: #0066ff;
  color: #fff;
}

input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #000;
}
input:focus {
  border-color: #0066ff;
}
.submit {
  margin-left: 40px;
}

select {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #000;
  cursor: pointer;
}
select:focus {
  border-color: #0066ff;
}

textarea {
  outline: 0;

  border-color: #000;
}

textarea:focus {
  border-color: #0066ff;
}

@media (max-width: 919px) {
  .join-form {
    max-width: 88vw;
  }
}
@media (max-width: 640px) {
  .join-form {
    max-width: 94vw;
  }
  .ins {
    margin-left: 25px;
  }
}

@media (max-width: 409px) {
  .texts input,
  select {
    width: 160px;
  }
  textarea {
    width: 120px;
    height: 250px;
  }
}
