.give-img {
  background-image: url("../../assets/Money.svg");
  width: 100%;
  height: 60vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 1600px;
}

.give-container {
  max-width: 85vw;
  /* width: 100%; */
  margin: 60px auto;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.give-article {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.give-container h2 {
  text-align: center;
  text-decoration: 4px underline;
  font-family: "Lato", sans-serif;
  font-size: 60px;
  font-weight: 600;
  padding-bottom: 60px;
}

.give-wrapper {
  border-radius: 0.5rem;
  box-shadow: 0 10px 30px rgba(56, 125, 255, 0.3);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.07));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.07));
  text-align: center;
  padding: 20px 0;
}

@media (max-width: 940px) {
  .give-article {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 640px) {
  .give-article {
    grid-template-columns: 1fr;
  }
}
