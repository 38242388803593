.links-container {
  margin-top: 30px;
}

.fa-fa {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  background-color: #fff;
  max-width: 400px;
  margin: 1rem auto;
}

.fab,
.fas {
  font-size: 50px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.fab:hover {
  transform: translateY(-5px);
}
.fas:hover {
  transform: translateY(-5px);
}

.fa-facebook-square {
  color: #3a3af8;
  background-color: #fff;
}

.fa-twitter-square {
  color: #00bfff;
  background-color: #fff;
}

.fa-instagram-square {
  background-color: #fff;
  color: #b22222;
}

.fa-youtube-square {
  background-color: #fff;
  color: red;
}

.fa-music {
  background-color: #fff;
  color: #1e90ff;
}

.follow {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
}
