.navbar {
  background-color: #fff;
  height: 80px;

  font-size: 1rem;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 1600px;
  font-weight: 700;
}

.navbar-logo img {
  color: #000;
  width: 80px;
  height: 80px;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
  color: #000;
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  text-align: center;
  /* width: 60vw; */
  width: 100%;
  gap: 0.8rem;
  justify-content: flex-end;
  margin-right: 2rem;
}

.nav-item a {
  text-decoration: none;
  color: #000;
  font-size: 18px;

  font-weight: 400;
  cursor: pointer;
}

.nav-item a::after {
  content: "";
  display: block;
  width: 100%;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}
a:hover::after {
  width: 100%;
}

/* .nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
} */

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 988px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 91vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navbar-logo img {
    position: absolute;

    width: 70px;
    height: 70px;

    top: -28px;
    left: -30px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000;
    font-size: 2rem;
  }

  .fa-bars {
    color: #000;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: flex;
    text-align: center;
    margin: 1rem auto;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #0066ff;
    padding: 14px 20px;
    border: 1px solid #0066ff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #0066ff;
    color: #fff;
    transition: 250ms;
  }
  .dropdown-content {
    display: none;
  }
  .nav-item {
    height: 55px;
  }
}

@media screen and (min-width: 960px) {
  .dropdown {
    display: inline-block;
  }

  .dropdown-content {
    position: absolute;
    display: none;
    background-color: #fff;
    padding-right: 70px;
    padding-top: 15px;
    padding-left: 5px;
    margin-left: 13px;
  }

  .dropdown-content a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #000;
    text-align: start;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding-top: 7px;
  }

  .dropdown-content a::after {
    content: "";
    text-decoration: none;
    display: flex;
    width: 100%;
    width: 0;

    height: 2px;
    background: #000;
    transition: width 0.3s;
  }
  a:hover::after {
    width: 100%;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}
